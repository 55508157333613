import { useContext, useState } from "react";
import { Context } from "../../../utils/context";
import AppHeader from "../../shared/AppHeader";
import Notification from "../../shared/Notification";
import GeneratingLoader from "./GeneratingLoader";
import "./Generating.scss";

const Generating = () => {
  const context = useContext(Context);
  const [animationStarted, setAnimationStarted] = useState(false);

  return (
    <div className="col col__1">
      {/* <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Generating" /> */}

      <div className="generating">
        <div className="generating__container">
          <GeneratingLoader
            config={{
              artist: context.activeSong.artist,
              animationStarted,
              setAnimationStarted,
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Generating;
