import React, { useEffect, useState } from "react";
import {
  Navigate,
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

import useAuth from "./utils/auth-hook";
import { useHttpClient } from "./utils/http-hook";
import AppContextWrapper from "./elements/shared/AppContextWrapper";
import Auth from "./elements/auth/Auth";
import Subscribe from "./elements/subscription/Subscribe";
import MainApp from "./elements/main/MainApp";
import "./light-mode.scss";
import "./media.scss";

const App = () => {
  const [error, setError] = useState("");
  const [songs, setSongs] = useState();
  const [favorites, setFavorites] = useState();
  const [activeSong, setActiveSong] = useState();
  const [tab, setTab] = useState("generate");
  const [comments, setComments] = useState();
  const [posts, setPosts] = useState();
  const [postComments, setPostComments] = useState();
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [activePost, setActivePost] = useState();
  const [editSong, setEditSong] = useState();
  const [subscriptionActive, setSubscriptionActive] = useState(true);
  const [subscriptionActiveUntil, setSubscriptionActiveUntil] = useState();
  const [user, setUser] = useState({});
  const [aiLoading, setAiLoading] = useState(false);
  const [attempt, setAttempt] = useState(0);
  const [audioRef, setAudioRef] = useState(null);
  const [activeAISong, setActiveAISong] = useState();
  const [activeRemix, setActiveRemix] = useState();
  const [savedMode, setSavedMode] = useState();
  const [mode, setMode] = useState();
  const [customMoods, setCustomMoods] = useState([]);
  const [customGenre, setCustomGenre] = useState([]);
  const [customTempo, setCustomTempo] = useState([]);
  const [customEnergyLevels, setCustomEnergyLevels] = useState([]);
  const [customMuteStems, setCustomMuteStems] = useState([]);
  const {
    token,
    login,
    logout,
    userId,
    admin,
    email,
    credits,
    theme,
    setTheme,
    setCredits,
  } = useAuth();
  const { sendRequest } = useHttpClient();

  useEffect(() => {
    const fetchAllSongs = async () => {
      const responseData = await sendRequest(
        "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/songs/get-all",
        "GET",
        null,
        {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        setSongs(responseData.songs);
        setActiveSong(
          localStorage.getItem("last-song") || responseData.songs[0]._id
        );
        audioRef && audioRef.current && audioRef.current.pause();
      }
    };

    if (token) fetchAllSongs();
  }, [token]);

  useEffect(() => {
    const fetchPlaylists = async () => {
      const responseData = await sendRequest(
        "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/users/get-playlists/" +
          userId,
        "GET",
        null,
        {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }
      );

      if (responseData) {
        setFavorites(responseData.playlists[0].songs.map((s) => s._id));
      }
    };

    if (token) fetchPlaylists();
  }, [token]);

  useEffect(() => {
    const fetchUserData = async () => {
      const responseData = await sendRequest(
        `https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/users/${userId}`,
        "GET",
        null,
        {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        }
      );

      if (responseData) {
        const { user } = responseData;
        setUser({
          name: user.name,
          email: user.email,
          img: user.img,
          imgPath: user.imgPath,
          subscriptionCanceled: user.subscriptionCanceled,
        });
        setSubscriptionActive(
          user.admin ||
            (user.subscriptionActiveUntil &&
              new Date(user.subscriptionActiveUntil).getTime() >
                new Date().getTime())
        );
        setSubscriptionActiveUntil(user.subscriptionActiveUntil);
      }
    };

    if (token && userId) fetchUserData();
  }, [token]);

  useEffect(() => {
    activeSong && localStorage.setItem("last-song", activeSong);
    setAttempt(0);
  }, [activeSong]);

  useEffect(() => {
    const song = songs ? songs.find((s) => s._id === activeSong) : null;

    setCustomGenre(song?.aiParameters?.[mode]?.genres || []);
    setCustomMoods(song?.aiParameters?.[mode]?.moods || []);
    setCustomMuteStems(song?.aiParameters?.[mode]?.muteStems || []);
    setCustomTempo(song?.aiParameters?.[mode]?.tempo || []);
    setCustomEnergyLevels(song?.aiParameters?.[mode]?.energyLevels || []);
  }, [mode, activeSong, tab]);

  const generateSong = async (songId, mode) => {
    if (aiLoading || (credits <= 0 && !admin)) return;

    let genre;
    if (mode) {
      switch (mode) {
        case "random":
          const arr = [
            "Trap",
            "Drill",
            "Lo-Fi",
            "Reggae",
            "Christmas",
            "Orchestra",
          ];
          const randomIndex = Math.floor(Math.random() * arr.length);
          genre = arr[randomIndex];
          break;
        default:
          genre = mode;
          break;
      }
      setSavedMode(mode);
    }

    setTab("generating");
    setAiLoading(true);

    try {
      const result = await sendRequest(
        "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/generate",
        "POST",
        JSON.stringify({
          genre: genre || savedMode,
          songId,
          attempt: attempt,
          customEnergyLevels: customEnergyLevels,
          customTempo: customTempo,
          customGenre: customGenre,
          customMoods: customMoods,
          customMuteStems: customMuteStems,
        }),
        {
          Authorization: "Bearer " + token,
          "Content-Type": "application/json",
        }
      );

      setTimeout(() => {
        if (result) {
          const userData = JSON.parse(localStorage.getItem("userData"));
          const newUserData = { ...userData, credits: credits - 1 };
          localStorage.setItem("userData", JSON.stringify(newUserData));
          setCredits((c) => c - 1);

          const song = songs.find((s) => s._id === songId);
          setActiveAISong({
            name: `${song.name}`,
            songURL: result.songUrl,
            songPath: result.songPath,
            artist: user.name,
            originalSongId: songId,
          });

          setAiLoading(false);
          setAttempt((a) => a + 1);
          setTab("generated-results");
        }
      }, 10000);
    } catch (e) {
      setAiLoading(false);
      setError(e.message);
    }
  };

  const spedUp = async () => {
    if (aiLoading) return;

    setAiLoading(true);
    setTab("generating");
    const result = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/sped-up",
      "POST",
      JSON.stringify({
        songURL: activeAISong.songURL,
        songPath: activeAISong.songPath,
      }),
      {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }
    );

    if (result) {
      setActiveAISong((song) => {
        return { ...song, songURL: result.songUrl };
      });
      setTab("generated-results");
    }
  };

  const slowDown = async () => {
    if (aiLoading) return;

    setAiLoading(true);
    setTab("generating");

    const result = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/ai/slow-down",
      "POST",
      JSON.stringify({
        songURL: activeAISong.songURL,
        songPath: activeAISong.songPath,
      }),
      {
        Authorization: "Bearer " + token,
        "Content-Type": "application/json",
      }
    );

    if (result) {
      setActiveAISong((song) => {
        return { ...song, songURL: result.songUrl };
      });
      setTab("generated-results");
    }
  };

  const value = {
    error,
    setError,
    login,
    logout,
    email,
    spedUp,
    slowDown,
    customMoods,
    customGenre,
    customTempo,
    setCustomTempo,
    customEnergyLevels,
    setCustomEnergyLevels,
    customMuteStems,
    credits,
    admin,
    setCustomMoods,
    setCustomGenre,
    setCustomMuteStems,
    songs,
    userId,
    favorites,
    setFavorites,
    token,
    activeSong,
    setActiveSong,
    tab,
    setTab,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    audioRef,
    setAudioRef,
    comments,
    setComments,
    subscriptionActive,
    subscriptionActiveUntil,
    user,
    setUser,
    activePost,
    setActivePost,
    postComments,
    setPostComments,
    posts,
    setPosts,
    editSong,
    setEditSong,
    setSongs,
    theme,
    setTheme,
    aiLoading,
    setAiLoading,
    generateSong,
    activeAISong,
    setActiveAISong,
    activeRemix,
    setActiveRemix,
    mode,
    setMode,
  };

  const router = createBrowserRouter([
    {
      path: "/login",
      element: (
        <AppContextWrapper value={value}>
          <Auth />
        </AppContextWrapper>
      ),
    },
    {
      path: "/signup",
      element: (
        <AppContextWrapper value={value}>
          <Auth />
        </AppContextWrapper>
      ),
    },
    {
      path: "/subscribe",
      element: (
        <AppContextWrapper value={value}>
          <Subscribe />
        </AppContextWrapper>
      ),
    },
    {
      path: "/player",
      element: (
        <AppContextWrapper value={value}>
          <MainApp />
        </AppContextWrapper>
      ),
    },
    { path: "*", element: <Navigate to="/signup" /> },
  ]);

  return <RouterProvider router={router} />;
};

export default App;
