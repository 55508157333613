import { useContext, useEffect, useRef, useState } from "react";
import { Context } from "../../../utils/context";
import Previous from "../../../assets/previous-button.png";
import Next from "../../../assets/next-button.png";
import Play from "../../../assets/play-pause.png";
import MusicLabel from "../../../assets/player-music-label.svg";
import MusicLabelDark from "../../../assets/player-music-label-dark.svg";
import Generate from "../../../assets/player-generate-mobile.jpg";
import EditRemix from "../../../assets/edit-remix-button-mobile.jpg";
import "./PlayerControls.scss";

const PlayerControls = () => {
  const [initialLoad, setInitialLoad] = useState(true);
  const [isPressed, setIsPressed] = useState({
    previous: false,
    playPause: false,
    next: false,
    generate: false,
  });

  const [isMobile, setIsMobile] = useState(window.innerWidth <= 1200);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", handleResize);
  }, []);

  const {
    activeSong,
    songs,
    setActiveSong,
    currentTime,
    setCurrentTime,
    duration,
    setDuration,
    setAudioRef,
    tab,
    activeAISong,
    theme,
    mode,
    setActiveRemix,
    setError,
    generateSong,
    setTab,
    aiLoading,
    admin,
  } = useContext(Context);

  const handleMouseDown = (button) => {
    setIsPressed((prevState) => ({ ...prevState, [button]: true }));
  };

  const handleMouseUp = (button) => {
    setIsPressed((prevState) => ({ ...prevState, [button]: false }));
  };

  const generateHandler = () => {
    if (!mode) {
      setError("Select a mode");
      setTimeout(() => {
        setError("");
      }, 3000);
      return;
    }

    if (admin) {
      setTab("generate-admin-settings");
    } else {
      generateSong(activeSong, mode);
    }
  };

  const previousHandler = () => {
    if (songs && activeSong) {
      let index = songs.findIndex((s) => s._id === activeSong);
      if (index === 0) {
        index = songs.length - 1;
      } else {
        index -= 1;
      }
      setActiveSong(songs[index]._id);
    }
  };

  const nextHandler = () => {
    if (songs && activeSong) {
      let index = songs.findIndex((s) => s._id === activeSong);
      if (index === songs.length - 1) {
        index = 0;
      } else {
        index += 1;
      }
      setActiveSong(songs[index]._id);
    }
  };

  const audioRef = useRef();
  const [isPlaying, setIsPlaying] = useState(false);

  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (audio.paused) {
        audio
          .play()
          .then(() => setIsPlaying(true))
          .catch((error) => console.log("Error playing audio:", error));
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    }
  };

  useEffect(() => {
    const audio = audioRef.current;
    setAudioRef(audioRef);

    const updateProgress = () => {
      setCurrentTime(audio.currentTime);
      setDuration(audio.duration || 0);
    };

    const handleCanPlayThrough = () => {
      if (
        !initialLoad &&
        tab !== "control-panel" &&
        tab !== "generated-results"
      ) {
        audio.play().catch((error) => {
          console.log("Autoplay prevented, waiting for user interaction");
        });
        setIsPlaying(true);
      } else {
        audio.pause();
        setIsPlaying(false);
      }
    };

    if (audio && activeSong) {
      audio.addEventListener("canplaythrough", handleCanPlayThrough);
      audio.addEventListener("timeupdate", updateProgress);
      audio.addEventListener("loadedmetadata", updateProgress);
      if (tab !== "generated-results")
        audio.src = songs.find((s) => s._id === activeSong)?.songURL || "";
    }

    if (initialLoad && audio) {
      audio.pause();
      setIsPlaying(false);
    }
  }, [
    activeSong,
    songs,
    initialLoad,
    setCurrentTime,
    setDuration,
    setAudioRef,
  ]);

  useEffect(() => {
    if (currentTime !== 0 && duration !== 0 && duration === currentTime)
      nextHandler();
  }, [duration, currentTime]);

  useEffect(() => {
    if (isPlaying) {
      setInitialLoad(false);
    }
  }, [isPlaying]);

  const song =
    songs && activeSong ? songs.find((s) => s._id === activeSong) : null;

  return (
    <div className="player_controls">
      <img
        className="player_controls__img"
        src={Previous}
        alt="Previous"
        onClick={previousHandler}
        onMouseDown={() => handleMouseDown("previous")}
        onMouseUp={() => handleMouseUp("previous")}
        style={isPressed.previous ? { transform: "scale(0.9)" } : {}}
      />

      <div className="player_controls__play">
        <p className={isPlaying ? "playing" : ""}>Play / Pause</p>
        <img
          src={Play}
          alt="Play/Pause"
          onClick={handlePlayPause}
          onMouseDown={() => handleMouseDown("playPause")}
          onMouseUp={() => handleMouseUp("playPause")}
          style={isPressed.playPause ? { transform: "scale(0.95)" } : {}}
        />
      </div>

      <img
        className="player_controls__img"
        src={Next}
        alt="Next"
        onClick={nextHandler}
        onMouseDown={() => handleMouseDown("next")}
        onMouseUp={() => handleMouseUp("next")}
        style={isPressed.next ? { transform: "scale(0.9)" } : {}}
      />

      {isMobile && (
        <div className="player_controls__mobile">
          <img
            className="player_controls__music_img"
            src={theme === "light" ? MusicLabelDark : MusicLabel}
            alt=""
          />

          <div className="player_controls__mobile_controls">
            <img src={Previous} alt="Previous" onClick={previousHandler} />
            <img src={Next} alt="Next" onClick={nextHandler} />
          </div>
        </div>
      )}

      {tab !== "community" &&
        tab !== "comments" &&
        tab !== "community_comments" &&
        song &&
        song.AIEnabled &&
        !aiLoading && (
          <img
            src={Generate}
            className="player_controls__generate"
            onClick={generateHandler}
            alt="Generate"
            onMouseDown={() => handleMouseDown("generate")}
            onMouseUp={() => handleMouseUp("generate")}
            style={isPressed.generate ? { transform: "scale(0.95)" } : {}}
          />
        )}

      {tab !== "community" &&
        tab !== "comments" &&
        tab !== "community_comments" &&
        song &&
        song.AIGenerated &&
        !aiLoading && (
          <img
            onClick={() => {
              setActiveRemix(songs.find((s) => s._id === activeSong));
              setTab("edit-remix");
            }}
            src={EditRemix}
            alt=""
            className="player_controls__generate"
            onMouseDown={() => handleMouseDown("generate")}
            onMouseUp={() => handleMouseUp("generate")}
            style={isPressed.generate ? { transform: "scale(0.95)" } : {}}
          />
        )}

      {song && (
        <audio
          ref={audioRef}
          src={
            tab === "generated-results" ? activeAISong.songURL : song.songURL
          }
        />
      )}
    </div>
  );
};

export default PlayerControls;
