import { useContext, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { firebaseUpload } from "../../../utils/upload-hook";
import { Context } from "../../../utils/context";
import { useHttpClient } from "../../../utils/http-hook";
import ModalContainer from "../../shared/ModalContainer";
import Notification from "../../shared/Notification";
import AppHeader from "../../shared/AppHeader";
import Close from "../../shared/Close";
import Loader from "../../shared/Loader";
import SongForm from "./SongForm";
import "./EditSong.scss";

const UploadSong = () => {
  const context = useContext(Context);
  const [originalId, setOriginalId] = useState("");
  const { isLoading, sendRequest, setIsLoading } = useHttpClient();
  const [formState, setFormState] = useState({
    songName: "",
    artist: "",
    producer: "",
    label: "",
    type: "slow",
  });

  const submitHandler = async (file, audioFile) => {
    setIsLoading(true);
    const firebaseId = uuidv4();

    const audioPath = `Audio/${firebaseId}/main.${audioFile.name
      .split(".")
      .pop()}`;

    const imgPath = `Pictures/SongsPics/${firebaseId}.${file.name
      .split(".")
      .pop()}`;

    try {
      const downloadURL = await uploadFileAndGetURL(audioFile, audioPath);
      const imgURL = await uploadFileAndGetURL(file, imgPath);

      const song = await saveSongData(
        imgURL,
        downloadURL,
        audioPath,
        imgPath,
        firebaseId
      );
      context.setSongs((songs) => [...songs, song]);
      context.setEditSong(song._id);
      context.setTab("edit_song");
    } catch (err) {}
  };

  const uploadFileAndGetURL = async (file, path) => {
    const [getUploadTask, getDownloadURL] = firebaseUpload(path, file);
    const uploadTask = getUploadTask();
    await uploadTask;
    return getDownloadURL(uploadTask.snapshot.ref);
  };

  const saveSongData = async (
    imgURL,
    songURL,
    audioPath,
    imgPath,
    firebaseId
  ) => {
    const response = await sendRequest(
      "https://us-central1-happy-monday-fb1b2.cloudfunctions.net/api/api/songs/save",
      "POST",
      JSON.stringify({
        name: formState.songName,
        songURL,
        artist: formState.artist,
        producer: formState.producer,
        label: formState.label,
        style: formState.type,
        imgURL,
        songPath: audioPath,
        imgPath,
        firebaseId,
        originalSongId: originalId,
      }),
      {
        "Content-Type": "application/json",
        Authorization: `Bearer ${context.token}`,
      }
    );

    return response.song;
  };

  return (
    <ModalContainer>
      <Notification
        text={`CREDITS [${context.admin ? "unlimited" : context.credits}]`}
      />
      <AppHeader text="Upload Demo" />
      <Close onClick={() => context.setTab("songs_settings")} />

      <div className="edit_song">
        <SongForm
          isLoading={isLoading}
          formState={formState}
          setFormState={setFormState}
          submitHandler={submitHandler}
          originalId={originalId}
          setOriginalId={setOriginalId}
        />
        {isLoading && <Loader />}
      </div>
    </ModalContainer>
  );
};

export default UploadSong;
